import { Button, Input, Modal, Select, Table, Checkbox } from "antd";
import React, { useEffect, useState } from "react";
import { useLoggerStore } from "../store/loggerStore";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { useCompanyStore } from "../store/companyStore";
import { ArrowLeftOutlined } from "@ant-design/icons";
import moment from "moment";
import Swal from "sweetalert2";
import { useIndustryTypeStore } from "../store/industryTypeStore";
import { LineOutlined } from "@ant-design/icons";
import { useThresholdsStore } from "../store/thresholdsStore";
import { useCityStore } from "../store/cityStore";
import { USER } from "../helpers/constant";
import { useSensorStore } from "../store/sensorStore";

function DetailCompany() {
  const { id } = useParams();
  const {
    fetchLoggerByCompanyId,
    loggersPerCompany,
    fetchLoggerById,
    logger,
    editLoggerInDetailCompany,
  } = useLoggerStore();
  const { company, fetchCompanyById, editCompany } = useCompanyStore();
  const { cities, fetchCities } = useCityStore();
  const { allIndustryTypes, fetchAllIndustryTypes } = useIndustryTypeStore();
  const {
    editThresholdsByIdThreshold,
    createThresholdByCompanyId,
    createThresholdByLoggerId,
  } = useThresholdsStore();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataUser, setDataUser] = useState({});
  const [formEditCompany, setFormEditCompany] = useState({
    company_name: "",
    industry_type_id: "",
    email: "",
    phone_number: "",
    address: "",
    city_id: "",
  });
  const [selectedIndustryType, setSelectedIndustryType] = useState("");
  const [isEditThresholdsModalOpen, setIsEditThresholdsModalOpen] =
    useState(false);
  const { sensors, fetchAllSensor } = useSensorStore();
  const [formEditThreshold, setFormEditThreshold] = useState({
    maxSuhu: "",
    // maxDhl: "",
    maxTds: "",
    maxDo: "",
    minpH: "",
    maxpH: "",
    maxTurbidity: "",
    maxKedalaman: "",
    maxNitrat: "",
    maxAmonia: "",
    maxCod: "",
    maxBod: "",
    maxTss: "",
    // maxSwsg: "",
    // maxSalinitas: "",
    // maxOrp: ""
  });
  const [formEditSensor, setFormEditSensor] = useState({ sensors: [] });
  const [formEditThresholdLogger, setFormEditThresholdLogger] = useState({
    maxSuhu: "",
    // maxDhl: "",
    maxTds: "",
    maxDo: "",
    minpH: "",
    maxpH: "",
    maxTurbidity: "",
    maxKedalaman: "",
    maxNitrat: "",
    maxAmonia: "",
    maxCod: "",
    maxBod: "",
    maxTss: "",
    // maxSwsg: "",
    // maxSalinitas: "",
    // maxOrp: ""
  });
  const [isEditThresholdsLoggerModalOpen, setIsEditThresholdsLoggerModalOpen] =
    useState(false);
  const [idLogger, setIdLogger] = useState("");
  let [searchParams, setSearchParams] = useSearchParams();
  const searchItems = searchParams.get("searchItems");

  const idCompany = company?._id;
  const id_logger = idLogger?.id_logger;
  const sensorOptions = sensors.map((item) => item.sensor_name);

  const convertDate = (date) => {
    const numDate = Number(date);
    return moment.unix(numDate).format("DD/MM/YYYY  h:mm:ss");
  };

  const columns = [
    {
      title: "Logger Name",
      dataIndex: "logger_name",
      key: "logger_name",
      render: (value, record) => value || "-",
    },
    {
      title: "Created Date",
      dataIndex: "created_date",
      key: "created_date",
      render: (value, record) => convertDate(record.created_date),
    },
    {
      title: "Sensor",
      dataIndex: "sensors",
      key: "sensors",
      render: (value, record) =>
        record.sensors.map((d) => d.sensor_name || "-").join(", ") || "-",
    },
    {
      title: () => {
        return (
          <div className="">
            <p className="m-0">Suhu</p>
            <sub>(°C)</sub>
          </div>
        );
      },
      dataIndex: "suhu",
      key: "suhu",
      render: (value, record) =>
        record?.log_data[record?.log_data?.length - 1]?.suhu || "-",
    },
    // {
    //     title: () => {
    //         return (
    //             <div className="">
    //                 <p className="m-0">Daya Hantar Listrik</p>
    //                 <sub>
    //                     (μS/cm)
    //                 </sub>
    //             </div>
    //         );
    //     },
    //     dataIndex: "dhl",
    //     key: "dhl",
    //     render: (value, record) =>
    //         record?.log_data[record?.log_data?.length - 1]?.dhl || "-",
    // },
    {
      title: () => {
        return (
          <div className="">
            <p className="m-0">TDS</p>
            <sub>(mg/L)</sub>
          </div>
        );
      },
      dataIndex: "tds",
      key: "tds",
      render: (value, record) =>
        record?.log_data[record?.log_data?.length - 1]?.tds || "-",
    },
    {
      title: () => {
        return (
          <div className="">
            <p className="m-0">DO</p>
            <sub>(mg/L)</sub>
          </div>
        );
      },
      dataIndex: "do",
      key: "do",
      render: (value, record) =>
        record?.log_data[record?.log_data?.length - 1]?.do || "-",
    },
    {
      title: () => {
        return (
          <div className="">
            <p className="m-0">pH</p>
          </div>
        );
      },
      dataIndex: "ph",
      key: "ph",
      render: (value, record) =>
        record?.log_data[record?.log_data?.length - 1]?.ph || "-",
    },
    {
      title: () => {
        return (
          <div className="">
            <p className="m-0">Turbidity</p>
            <sub>(NTU)</sub>
          </div>
        );
      },
      dataIndex: "turbidity",
      key: "turbidity",
      render: (value, record) =>
        record?.log_data[record?.log_data?.length - 1]?.turbidity || "-",
    },
    {
      title: () => {
        return (
          <div className="">
            <p className="m-0">Kedalaman</p>
            <sub>(m)</sub>
          </div>
        );
      },
      dataIndex: "kedalaman",
      key: "kedalaman",
      render: (value, record) =>
        record?.log_data[record?.log_data?.length - 1]?.kedalaman || "-",
    },
    {
      title: () => {
        return (
          <div className="">
            <p className="m-0">Nitrat</p>
            <sub>(mg/L)</sub>
          </div>
        );
      },
      dataIndex: "nitrat",
      key: "nitrat",
      render: (value, record) =>
        record?.log_data[record?.log_data?.length - 1]?.nitrat || "-",
    },
    {
      title: () => {
        return (
          <div className="">
            <p className="m-0">Amonia</p>
            <sub>(mg/L)</sub>
          </div>
        );
      },
      dataIndex: "amonia",
      key: "amonia",
      render: (value, record) =>
        record?.log_data[record?.log_data?.length - 1]?.amonia || "-",
    },
    {
      title: () => {
        return (
          <div className="">
            <p className="m-0">COD</p>
            <sub>(mg/L)</sub>
          </div>
        );
      },
      dataIndex: "cod",
      key: "cod",
      render: (value, record) =>
        record?.log_data[record?.log_data?.length - 1]?.cod || "-",
    },
    {
      title: () => {
        return (
          <div className="">
            <p className="m-0">BOD</p>
            <sub>(mg/L)</sub>
          </div>
        );
      },
      dataIndex: "tss",
      key: "tss",
      render: (value, record) =>
        record?.log_data[record?.log_data?.length - 1]?.bod || "-",
    },
    {
      title: () => {
        return (
          <div className="">
            <p className="m-0">TSS</p>
            <sub>(mg/L)</sub>
          </div>
        );
      },
      dataIndex: "tss",
      key: "tss",
      render: (value, record) =>
        record?.log_data[record?.log_data?.length - 1]?.tss || "-",
    },

    // {
    //     title: () => {
    //         return (
    //             <div className="">
    //                 <p className="m-0">SwSG</p>
    //                 <sub>
    //                     (mg/L)
    //                 </sub>
    //             </div>
    //         );
    //     },
    //     dataIndex: "swsg",
    //     key: "swsg",
    //     render: (value, record) =>
    //         record?.log_data[record?.log_data?.length - 1]?.swsg || "-",
    // },
    // {
    //     title: () => {
    //         return (
    //             <div className="">
    //                 <p className="m-0">Salinitas</p>
    //                 <sub>
    //                     (mg/L)
    //                 </sub>
    //             </div>
    //         );
    //     },
    //     dataIndex: "salinitas",
    //     key: "salinitas",
    //     render: (value, record) =>
    //         record?.log_data[record?.log_data?.length - 1]?.salinitas || "-",
    // },
    // {
    //     title: () => {
    //         return (
    //             <div className="">
    //                 <p className="m-0">ORP</p>
    //                 <sub>
    //                     (mg/L)
    //                 </sub>
    //             </div>
    //         );
    //     },
    //     dataIndex: "orp",
    //     key: "orp",
    //     render: (value, record) =>
    //         record?.log_data[record?.log_data?.length - 1]?.orp || "-",
    // },

    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div className="flex gap-2 items-center">
          <Link to={`/logger/${record._id}`}>
            <Button className="border-blue-500 border-1 text-blue-500 ">
              View Detail
            </Button>
          </Link>
          {["admin", "developer"].includes(dataUser?.role) && (
            <Button
              onClick={() => handleEditThresholdLogger(record)}
              className="bg-orange-500 text-white"
              type="text"
            >
              Edit Sensor & Thresholds
            </Button>
          )}
        </div>
      ),
    },
  ];

  const columnThresholds = [
    {
      title: () => {
        return (
          <div className="">
            <p className="m-0">Max Suhu</p>
            <sub>(°C)</sub>
          </div>
        );
      },
      dataIndex: "maxSuhu",
      key: "maxSuhu",
    },
    // {
    //     title: () => {
    //         return (
    //             <div className="">
    //                 <p className="m-0">Max Daya Hantar Listrik</p>
    //                 <sub>(μS/cm)</sub>
    //             </div>
    //         );
    //     },
    //     dataIndex: "maxDhl",
    //     key: "maxDhl",
    // },
    {
      title: () => {
        return (
          <div className="">
            <p className="m-0">Max TDS</p>
            <sub>(mg/L)</sub>
          </div>
        );
      },
      dataIndex: "maxTds",
      key: "maxTds",
    },
    {
      title: () => {
        return (
          <div className="">
            <p className="m-0">Max DO</p>
            <sub>(mg/L)</sub>
          </div>
        );
      },
      dataIndex: "maxDo",
      key: "maxDo",
    },
    {
      title: () => {
        return (
          <div className="">
            <p className="m-0">pH</p>
          </div>
        );
      },
      dataIndex: "ph",
      key: "ph",
      render: (value, record) => (
        <div className="flex gap-1">
          {record?.minpH || ""}
          <LineOutlined />
          {record?.maxpH || ""}
        </div>
      ),
    },
    {
      title: () => {
        return (
          <div className="">
            <p className="m-0">Max Turbidity</p>
            <sub>(NTU)</sub>
          </div>
        );
      },
      dataIndex: "maxTurbidity",
      key: "maxTurbidity",
    },
    {
      title: () => {
        return (
          <div className="">
            <p className="m-0">Max Kedalaman</p>
            <sub>(m)</sub>
          </div>
        );
      },
      dataIndex: "maxKedalaman",
      key: "maxKedalaman",
    },
    {
      title: () => {
        return (
          <div className="">
            <p className="m-0">Max Nitrat</p>
            <sub>(mg/L)</sub>
          </div>
        );
      },
      dataIndex: "maxNitrat",
      key: "maxNitrat",
    },
    {
      title: () => {
        return (
          <div className="">
            <p className="m-0">Max Amonia</p>
            <sub>(mg/L)</sub>
          </div>
        );
      },
      dataIndex: "maxAmonia",
      key: "maxAmonia",
    },
    {
      title: () => {
        return (
          <div className="">
            <p className="m-0">Max COD</p>
            <sub>(mg/L)</sub>
          </div>
        );
      },
      dataIndex: "maxCod",
      key: "maxCod",
    },
    {
      title: () => {
        return (
          <div className="">
            <p className="m-0">Max BOD</p>
            <sub>(mg/L)</sub>
          </div>
        );
      },
      dataIndex: "maxBod",
      key: "maxBod",
    },
    {
      title: () => {
        return (
          <div className="">
            <p className="m-0">Max TSS</p>
            <sub>(mg/L)</sub>
          </div>
        );
      },
      dataIndex: "maxTss",
      key: "maxTss",
    },

    // {
    //     title: () => {
    //         return (
    //             <div className="">
    //                 <p className="m-0">Max SwSG</p>
    //                 <sub>(mg/L)</sub>
    //             </div>
    //         );
    //     },
    //     dataIndex: "maxSwsg",
    //     key: "maxSwsg",
    // },
    // {
    //     title: () => {
    //         return (
    //             <div className="">
    //                 <p className="m-0">Max Salinitas</p>
    //                 <sub>(mg/L)</sub>
    //             </div>
    //         );
    //     },
    //     dataIndex: "maxSalinitas",
    //     key: "maxSalinitas",
    // },
    // {
    //     title: () => {
    //         return (
    //             <div className="">
    //                 <p className="m-0">Max ORP</p>
    //                 <sub>(mg/L)</sub>
    //             </div>
    //         );
    //     },
    //     dataIndex: "maxOrp",
    //     key: "maxOrp",
    // },

    {
      title: () => {
        return (
          ["admin", "developer"].includes(dataUser?.role) && (
            <p className="m-0">Action</p>
          )
        );
      },
      key: "action",
      render: (_, record) => (
        <>
          {["admin", "developer"].includes(dataUser?.role) && (
            <Button
              onClick={() => handleEditThreshold(record)}
              className="border-blue-500 border-1 text-blue-500 "
            >
              Edit Thresholds Company
            </Button>
          )}
        </>
      ),
    },
  ];

  //function for edit threshold
  const handleCancelEditModal = () => {
    setIsEditThresholdsModalOpen(false);
  };

  const handleEditThreshold = (record) => {
    // setIdThreshold(record?.id_threshold?._id);
    setFormEditThreshold({
      maxSuhu: record?.maxSuhu || "-",
      // maxDhl: record?.maxDhl || "-",
      maxTds: record?.maxTds || "-",
      maxDo: record?.maxDo || "-",
      minpH: record?.minpH || "-",
      maxpH: record?.maxpH || "-",
      maxTurbidity: record?.maxTurbidity || "-",
      maxKedalaman: record?.maxKedalaman || "-",
      maxNitrat: record?.maxNitrat || "-",
      maxAmonia: record?.maxAmonia || "-",
      maxCod: record?.maxCod || "-",
      maxBod: record?.maxBod || "-",
      maxTss: record?.maxTss || "-",
      // maxSwsg: record?.maxSwsg || "-",
      // maxSalinitas: record?.maxSalinitas || "-",
      // maxOrp: record?.maxOrp || "-",
    });
    setIsEditThresholdsModalOpen(true);
  };

  const handleEditThresholdForm = async (event) => {
    const key = event.target.name;

    setFormEditThreshold({ ...formEditThreshold, [key]: event.target.value });
  };

  const handleEditThresholdConfirmation = async () => {
    // setIsModalOpen(false);
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You want to edit this threshold?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Edit Threshold",
    });
    if (result.isConfirmed) {
      submitEditThresholdHandler();
    }
  };

  const submitEditThresholdHandler = async () => {
    try {
      setIsLoading(true);

      if (company.id_threshold) {
        // alert("company");
        const idThreshold = company?.id_threshold?._id;
        editThresholdsByIdThreshold(idThreshold, formEditThreshold);
      } else {
        // alert("industry");
        if (idCompany) {
          createThresholdByCompanyId(idCompany, formEditThreshold);
        }
      }
      // editIndustryType(idIndustryType, formEditIndustry);
      // editThresholdsByIdThreshold(idThreshold, formEditIndustry);
      setIsLoading(false);
      setIsEditThresholdsModalOpen(false);

      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Success edit thresholds",
        showConfirmButton: false,
      });

      setFormEditThreshold({
        maxSuhu: "",
        // maxDhl: "",
        maxTds: "",
        maxDo: "",
        minpH: "",
        maxpH: "",
        maxTurbidity: "",
        maxKedalaman: "",
        maxNitrat: "",
        maxAmonia: "",
        maxCod: "",
        maxBod: "",
        maxTss: "",
        // maxSwsg : "",
        // maxSalinitas : "",
        // maxOrp : ""
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!!",
      });
      setIsLoading(false);
      setIsEditThresholdsModalOpen(false);
    }
  };

  //function for edit threshold logger
  const handleCancelEditThresholdsModal = () => {
    setIsEditThresholdsLoggerModalOpen(false);
  };

  const handleEditThresholdLogger = (record) => {
    // setIdThreshold(record?.id_threshold?._id);
    setIdLogger({ id_logger: record?._id });
    setFormEditSensor({
      sensors: (record?.sensors || []).map((sensor) => sensor._id),
    });
    setFormEditThresholdLogger({
      maxSuhu:
        record?.thresholds_logger[0]?.maxSuhu || company?.id_threshold?.maxSuhu,
      // maxDhl:
      //     record?.thresholds_logger[0]?.maxDhl || company?.id_threshold?.maxDhl,
      maxTds:
        record?.thresholds_logger[0]?.maxTds || company?.id_threshold?.maxTds,
      maxDo:
        record?.thresholds_logger[0]?.maxDo || company?.id_threshold?.maxDo,
      minpH:
        record?.thresholds_logger[0]?.minpH || company?.id_threshold?.minpH,
      maxpH:
        record?.thresholds_logger[0]?.maxpH || company?.id_threshold?.maxpH,
      maxTurbidity:
        record?.thresholds_logger[0]?.maxTurbidity ||
        company?.id_threshold?.maxTurbidity,
      maxKedalaman:
        record?.thresholds_logger[0]?.maxKedalaman ||
        company?.id_threshold?.maxKedalaman,
      maxNitrat:
        record?.thresholds_logger[0]?.maxNitrat ||
        company?.id_threshold?.maxNitrat,
      maxAmonia:
        record?.thresholds_logger[0]?.maxAmonia ||
        company?.id_threshold?.maxAmonia,
      maxCod:
        record?.thresholds_logger[0]?.maxCod || company?.id_threshold?.maxCod,
      maxBod:
        record?.thresholds_logger[0]?.maxBod || company?.id_threshold?.maxBod,
      maxTss:
        record?.thresholds_logger[0]?.maxTss || company?.id_threshold?.maxTss,
      // maxSwsg:
      //     record?.thresholds_logger[0]?.maxSwsg || company?.id_threshold?.maxSwsg,
      // maxSalinitas:
      //     record?.thresholds_logger[0]?.maxSalinitas || company?.id_threshold?.maxSalinitas,
      // maxOrp:
      //     record?.thresholds_logger[0]?.maxOrp || company?.id_threshold?.maxOrp,
    });
    setIsEditThresholdsLoggerModalOpen(true);
  };

  const handleEditThresholdLoggerForm = async (event) => {
    const key = event.target.name;

    setFormEditThresholdLogger({
      ...formEditThresholdLogger,
      [key]: event.target.value,
    });

    setFormEditSensor({ ...formEditSensor, [key]: event.target.value });
  };

  const handleEditThresholdLoggerConfirmation = async () => {
    // setIsModalOpen(false);
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You want to edit this threshold?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Edit Threshold",
    });
    if (result.isConfirmed) {
      submitEditThresholdLoggerHandler();
    }
  };

  const submitEditThresholdLoggerHandler = async () => {
    try {
      setIsLoading(true);

      if (logger?.id_threshold) {
        const idThreshold = logger?.id_threshold;
        editThresholdsByIdThreshold(idThreshold, formEditThresholdLogger);
      } else {
        if (idLogger) {
          createThresholdByLoggerId(idLogger, formEditThresholdLogger);
        }
      }

      if (logger?.sensors) {
        editLoggerInDetailCompany(id, id_logger, formEditSensor);
      }

      setIsLoading(false);
      setIsEditThresholdsLoggerModalOpen(false);

      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Success edit thresholds",
        showConfirmButton: false,
      });

      setFormEditSensor({ sensors: [] });

      setFormEditThresholdLogger({
        maxSuhu: "",
        // maxDhl: "",
        maxTds: "",
        maxDo: "",
        minpH: "",
        maxpH: "",
        maxTurbidity: "",
        maxKedalaman: "",
        maxNitrat: "",
        maxAmonia: "",
        maxCod: "",
        maxBod: "",
        maxTss: "",
        // maxSwsg : "",
        // maxSalinitas : "",
        // maxOrp : ""
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!!",
      });
      setIsLoading(false);
      setIsEditThresholdsLoggerModalOpen(false);
    }
  };

  //function for modal
  const showModal = () => {
    setFormEditCompany((prev) => ({
      ...prev,
      industry_type_id: company.industry_type_id,
      city_id: company.city_id,
    }));
    setIsModalOpen(true);
  };
  const handleOk = async (data) => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleEditCompanyForm = async (event) => {
    const key = event.target.name;

    setFormEditCompany({ ...formEditCompany, [key]: event.target.value });
  };

  const handleConfirmationEditCompany = async () => {
    // setIsModalOpen(false);
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You want to edit this company?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Edit company",
    });
    if (result.isConfirmed) {
      onClickSubmitEditHandler(id);
    }
  };

  const onClickSubmitEditHandler = async () => {
    try {
      setIsLoading(true);
      editCompany(id, formEditCompany);
      setIsLoading(false);
      setIsModalOpen(false);

      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Success edit company",
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!!",
      });
      setIsLoading(false);
      setIsModalOpen(false);
    }
  };

  //function for select industry type dropdown
  const onSearch = (value) => {};

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const onChange = (value, label) => {
    setFormEditCompany((prev) => ({
      ...prev,
      industry_type_id: { industry_type: label.label, _id: value },
    }));
  };
  //

  //function for select cities
  const onChangeCity = (value, label) => {
    setFormEditCompany(value);
    setFormEditCompany((prev) => ({
      ...prev,
      city_id: { city: label.label, _id: value },
    }));
  };

  const onSearchCity = (value) => {};

  const filterOptionCity = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  //

  // function for checkbox sensor
  const onChangeEditSensor = (checkedValues) => {
    setFormEditSensor({ ...formEditSensor, sensors: checkedValues });
  };
  //

  useEffect(() => {
    fetchAllIndustryTypes({ searchItems });
  }, [searchItems]);

  useEffect(() => {
    fetchCompanyById(id);
  }, []);

  useEffect(() => {
    fetchLoggerByCompanyId(id);
  }, []);

  useEffect(() => {
    fetchCities();
  }, []);

  useEffect(() => {
    if (id_logger) {
      fetchLoggerById(id_logger);
    }
  }, [id_logger]);

  useEffect(() => {
    setDataUser(JSON.parse(localStorage.getItem(USER)));
  }, [localStorage[USER]]);

  useEffect(() => {
    fetchAllSensor({ searchItems });
  }, [searchItems]);

  return (
    <div>
      {/* <Link to={"/company"}>
                <ArrowLeftOutlined />
            </Link> */}
      <p className="text-xl font-bold">Detail of Company</p>
      <div className="w-full mb-4 flex flex-wrap sm:flex-nowrap sm:gap-11 gap-2">
        <div className="flex justify-between pr-11 gap-4 overflow-auto">
          <div className="font-bold min-w-fit">
            <p>Company Name</p>
            <p>Industry Type</p>
            <p>Email</p>
            <p>Location</p>
          </div>

          <div className="min-w-fit">
            <p>: {company.company_name}</p>
            <p>: {company.industry_type_id?.industry_type || "-"}</p>
            <p>: {company.email ? company.email : "-"}</p>
            <p>
              : {company.address}, {company.city_id?.city_name || ""}
            </p>
          </div>
        </div>

        {["admin", "developer"].includes(dataUser?.role) && (
          <Button
            className="bg-green-600 text-white font-bold"
            type="text"
            onClick={showModal}
          >
            Edit Company Profile
          </Button>
        )}
      </div>
      {/* <hr /> */}
      <div className="mb-11">
        <p className="text-lg font-bold">Thresholds</p>
        <Table
          dataSource={
            company?.id_threshold
              ? [company?.id_threshold]
              : [company?.industry_type_id?.id_threshold]
          }
          columns={columnThresholds}
          pagination={false}
          scroll={{ x: "max-content" }}
        />
      </div>
      <hr />
      <div className="">
        <div className="text-lg font-semibold flex flex-wrap lg:flex-nowrap gap-2">
          <p>List logger of</p>
          <p className="text-blue-700">{company.company_name}</p>
        </div>
        <div>
          <Table
            dataSource={loggersPerCompany}
            columns={columns}
            pagination={{ pageSize: 5 }}
            scroll={{ x: "max-content" }}
          />
        </div>
      </div>

      <Modal
        title="Edit Company"
        open={isModalOpen}
        // onOk={handleOk}
        okText="Edit"
        onCancel={handleCancel}
        footer={null}
      >
        <>
          <p className="mb-1">Company Name:</p>
          <Input
            defaultValue={company.company_name}
            onChange={handleEditCompanyForm}
            name="company_name"
            id="company_name"
            // value={formEditCompany.company_name}
          />
          <p className="mb-1">Address:</p>
          <Input
            defaultValue={company.address}
            onChange={handleEditCompanyForm}
            name="address"
            id="address"
            // value={formEditCompany.address}
          />

          <div className="my-6">
            <label
              htmlFor="city"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              City
            </label>
            <Select
              showSearch
              id="city_id"
              name="city_id"
              required
              placeholder="City"
              optionFilterProp="children"
              onChange={onChangeCity}
              onSearch={onSearchCity}
              filterOption={filterOptionCity}
              className="w-full"
              value={formEditCompany?.city_id?.city_name}
              options={(cities || []).map((data) => ({
                key: data._id,
                value: data._id,
                label: data.city_name,
              }))}
            />
          </div>

          <div className="my-6">
            <label
              htmlFor="industry-type"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Industry Type
            </label>
            <Select
              showSearch
              id="industry_type_id"
              name="industry_type_id"
              required
              placeholder="Industry Type"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={filterOption}
              value={formEditCompany?.industry_type_id?.industry_type}
              className="w-full"
              options={(allIndustryTypes || []).map((data) => ({
                key: data._id,
                value: data._id,
                label: data.industry_type,
              }))}
            />
          </div>

          <p className="mb-1">Email:</p>
          <Input
            defaultValue={company.email ? company.email : "-"}
            onChange={handleEditCompanyForm}
            name="email"
            id="email"
            // value={formEditCompany.email}
          />
          <p className="mb-1">Phone Number:</p>
          <Input
            defaultValue={company.phone_number ? company.phone_number : "-"}
            onChange={handleEditCompanyForm}
            name="phone_number"
            id="phone_number"
            // value={formEditCompany.phone_number}
          />
          {/* <p className="mb-1">Parent Company:</p>
          <Input
            defaultValue={company.parent_company}
            onChange={handleEditCompanyForm}
            name="parent_company_id"
            id="parent_company_id"
            // value={formEditCompany.parent_company_id}
          /> */}
        </>
        <div className="flex justify-end gap-2 mt-4">
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>

          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            loading={isLoading}
            onClick={() => {
              handleConfirmationEditCompany();
            }}
          >
            Edit
          </Button>
        </div>
      </Modal>

      <Modal
        title="Edit Threshold"
        open={isEditThresholdsModalOpen}
        okText="Edit"
        onCancel={handleCancelEditModal}
        footer={null}
      >
        <p className="mb-1">Max Suhu</p>
        <Input
          onChange={handleEditThresholdForm}
          name="maxSuhu"
          id="maxSuhu"
          value={formEditThreshold.maxSuhu}
        />

        {/* <p className="mb-1">Max Daya Hantar Listrik</p>
                <Input
                    onChange={handleEditThresholdForm}
                    name="maxDhl"
                    id="maxDhl"
                    value={formEditThreshold.maxDhl}
                /> */}

        <p className="mb-1">Max TDS</p>
        <Input
          onChange={handleEditThresholdForm}
          name="maxTds"
          id="maxTds"
          value={formEditThreshold.maxTds}
        />

        <p className="mb-1">Max DO</p>
        <Input
          onChange={handleEditThresholdForm}
          name="maxDo"
          id="maxDo"
          value={formEditThreshold.maxDo}
        />

        <div className="flex gap-5">
          <div className="w-[48%]">
            <p className="mb-1">Min pH</p>
            <Input
              onChange={handleEditThresholdForm}
              name="minpH"
              id="minpH"
              value={formEditThreshold.minpH}
            />
          </div>

          <div className="w-[48%]">
            <p className="mb-1">Max pH</p>
            <Input
              onChange={handleEditThresholdForm}
              name="maxpH"
              id="maxpH"
              value={formEditThreshold.maxpH}
            />
          </div>
        </div>

        <p className="mb-1">Max Turbidity</p>
        <Input
          onChange={handleEditThresholdForm}
          name="maxTurbidity"
          id="maxTurbidity"
          value={formEditThreshold.maxTurbidity}
        />

        <p className="mb-1">Max Kedalaman</p>
        <Input
          onChange={handleEditThresholdForm}
          name="maxKedalaman"
          id="maxKedalaman"
          value={formEditThreshold.maxKedalaman}
        />

        <p className="mb-1">Max Nitrat</p>
        <Input
          onChange={handleEditThresholdForm}
          name="maxNitrat"
          id="maxNitrat"
          value={formEditThreshold.maxNitrat}
        />

        <p className="mb-1">Max Amonia</p>
        <Input
          onChange={handleEditThresholdForm}
          name="maxAmonia"
          id="maxAmonia"
          value={formEditThreshold.maxAmonia}
        />

        <p className="mb-1">Max COD</p>
        <Input
          onChange={handleEditThresholdForm}
          name="maxCod"
          id="maxCod"
          value={formEditThreshold.maxCod}
        />

        <p className="mb-1">Max BOD</p>
        <Input
          onChange={handleEditThresholdForm}
          name="maxBod"
          id="maxBod"
          value={formEditThreshold.maxBod}
        />

        <p className="mb-1">Max TSS</p>
        <Input
          onChange={handleEditThresholdForm}
          name="maxTss"
          id="maxTss"
          value={formEditThreshold.maxTss}
        />

        {/* <p className="mb-1">Max SwSG</p>
                <Input
                    onChange={handleEditThresholdForm}
                    name="maxSwsg"
                    id="maxSwsg"
                    value={formEditThreshold.maxSwsg}
                />

                <p className="mb-1">Max Salinitas</p>
                <Input
                    onChange={handleEditThresholdForm}
                    name="maxSalinitas"
                    id="maxSalinitas"
                    value={formEditThreshold.maxSalinitas}
                />

                <p className="mb-1">Max ORP</p>
                <Input
                    onChange={handleEditThresholdForm}
                    name="maxOrp"
                    id="maxOrp"
                    value={formEditThreshold.maxOrp}
                /> */}

        <div className="flex justify-end gap-2 mt-4">
          <Button key="back" onClick={handleCancelEditModal}>
            Cancel
          </Button>

          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            loading={isLoading}
            onClick={() => {
              handleEditThresholdConfirmation();
            }}
          >
            Edit
          </Button>
        </div>
      </Modal>

      <Modal
        title="Edit Sensor & Threshold"
        open={isEditThresholdsLoggerModalOpen}
        okText="Edit"
        onCancel={handleCancelEditThresholdsModal}
        footer={null}
      >
        <div>
          <p htmlFor="activeSensor" className="mb-1">
            Active Sensor
          </p>
          <Checkbox.Group
            // options={sensorOptions}
            onChange={onChangeEditSensor}
            value={formEditSensor.sensors}
            options={(sensors || []).map((data) => ({
              key: data._id,
              value: data._id,
              label: data.sensor_name,
            }))}
          />
        </div>

        <p className="mb-1">Max Suhu</p>
        <Input
          onChange={handleEditThresholdLoggerForm}
          name="maxSuhu"
          id="maxSuhu"
          value={formEditThresholdLogger.maxSuhu}
        />

        {/* <p className="mb-1">Max Daya Hantar Listrik</p>
                <Input
                    onChange={handleEditThresholdLoggerForm}
                    name="maxDhl"
                    id="maxDhl"
                    value={formEditThresholdLogger.maxDhl}
                /> */}

        <p className="mb-1">Max TDS</p>
        <Input
          onChange={handleEditThresholdLoggerForm}
          name="maxTds"
          id="maxTds"
          value={formEditThresholdLogger.maxTds}
        />

        <p className="mb-1">Max Do</p>
        <Input
          onChange={handleEditThresholdLoggerForm}
          name="maxDo"
          id="maxDo"
          value={formEditThresholdLogger.maxDo}
        />

        <div className="flex gap-5">
          <div className="w-[48%]">
            <p className="mb-1">Min pH</p>
            <Input
              onChange={handleEditThresholdLoggerForm}
              name="minpH"
              id="minpH"
              value={formEditThresholdLogger.minpH}
            />
          </div>

          <div className="w-[48%]">
            <p className="mb-1">Max pH</p>
            <Input
              onChange={handleEditThresholdLoggerForm}
              name="maxpH"
              id="maxpH"
              value={formEditThresholdLogger.maxpH}
            />
          </div>
        </div>

        <p className="mb-1">Max Turbidity</p>
        <Input
          onChange={handleEditThresholdLoggerForm}
          name="maxTurbidity"
          id="maxTurbidity"
          value={formEditThresholdLogger.maxTurbidity}
        />

        <p className="mb-1">Max Kedalaman</p>
        <Input
          onChange={handleEditThresholdLoggerForm}
          name="maxKedalaman"
          id="maxKedalaman"
          value={formEditThresholdLogger.maxKedalaman}
        />

        <p className="mb-1">Max Nitrat</p>
        <Input
          onChange={handleEditThresholdLoggerForm}
          name="maxNitrat"
          id="maxNitrat"
          value={formEditThresholdLogger.maxNitrat}
        />

        <p className="mb-1">Max Amonia</p>
        <Input
          onChange={handleEditThresholdLoggerForm}
          name="maxAmonia"
          id="maxAmonia"
          value={formEditThresholdLogger.maxAmonia}
        />

        <p className="mb-1">Max COD</p>
        <Input
          onChange={handleEditThresholdLoggerForm}
          name="maxCod"
          id="maxCod"
          value={formEditThresholdLogger.maxCod}
        />

        <p className="mb-1">Max BOD</p>
        <Input
          onChange={handleEditThresholdLoggerForm}
          name="maxBod"
          id="maxBod"
          value={formEditThresholdLogger.maxBod}
        />

        <p className="mb-1">Max TSS</p>
        <Input
          onChange={handleEditThresholdLoggerForm}
          name="maxTss"
          id="maxTss"
          value={formEditThresholdLogger.maxTss}
        />

        {/* <p className="mb-1">Max SwSG</p>
                <Input
                    onChange={handleEditThresholdLoggerForm}
                    name="maxSwsg"
                    id="maxSwsg"
                    value={formEditThresholdLogger.maxSwsg}
                />

                <p className="mb-1">Max Salinitas</p>
                <Input
                    onChange={handleEditThresholdLoggerForm}
                    name="maxSalinitas"
                    id="maxSalinitas"
                    value={formEditThresholdLogger.maxSalinitas}
                />

                <p className="mb-1">Max ORP</p>
                <Input
                    onChange={handleEditThresholdLoggerForm}
                    name="maxOrp"
                    id="maxOrp"
                    value={formEditThresholdLogger.maxOrp}
                /> */}

        <div className="flex justify-end gap-2 mt-4">
          <Button key="back" onClick={handleCancelEditThresholdsModal}>
            Cancel
          </Button>

          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            loading={isLoading}
            onClick={() => {
              handleEditThresholdLoggerConfirmation();
            }}
          >
            Edit
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default DetailCompany;
